import React, { useMemo } from 'react'
import '../styles/solutionsAI.scss'
import Layout from '../components/layout'
import Heading from '../components/technologies/Heading'
import Overview from '../components/solutions/AI/Overview'
import About from '../components/solutions/AI/About'
import SEO from "../components/seo"
import { graphql } from "gatsby"
function Technologies({ data }) {
    const getAIData = (data) => {
        const values = data.allStrapiArtificialIntelligence.edges[0].node;
        return values;
    }
    const getData = useMemo(() => getAIData(data), [data]);
    return (

        <Layout slug={getData.slug} locale={getData.locale}>
            <SEO slug={getData.slug} locale={getData.locale} title={getData.metaTitle} description={getData.metaDes}/>
            <Heading navbar={getData.navbar} herosection={getData.herosection} locale={getData.locale}/>
            <Overview overview={getData.overview} opsioAI={getData.opsioAI} locale={getData.locale}/>
            {/* <About opsioAI={getData.opsioAI} locale={getData.locale} /> */}
        </Layout>
    )
}

export default Technologies
export const query = (graphql`
query Technologies($locale:String!) { 
allStrapiArtificialIntelligence(filter: {locale: {eq: $locale}}) {
    edges {
        node {
          slug
          locale
          metaTitle
          metaDes
          herosection {
            btn
            heading1
            heading2
            title
          }
          navbar {
            l1 {
              label
              slug
            }
            l2 {
              label
              slug
            }
            l3 {
              label
              slug
            }
            l4 {
              label
              slug
            }
            l5 {
              label
              slug
            }
            l6 {
              label
              slug
            }
            l7 {
              label
              slug
            }
          }
          opsioAI {
            btn
            des
            heading
            note
            service
            title
          }
          overview {
            id
            des
            heading
            title
          }
        }
      }
    }
  }
`)
