import React from 'react'
import { navigate, Link } from 'gatsby';
import Navigation from '../solutions/AI/Navigation'

function Heading({ herosection, navbar, locale }) {
    return (<>
        <div className="technology_upper_class">
        
            <div className="solutionsAIHeroSection" id="nav-heading">
                <div className="containerwrap">
                    <div className="solutionsAIHeroSectionTitle">{herosection.title}</div>
                    <h1 className="solutionsAISectionHeading">{herosection.heading1} <span style={{ color: "#58C9E8" }}>{herosection.heading2}</span></h1>
                    {/* <div className="solutionsAISectionHeading">with the help of AI</div> */}
                    <div className="opsBtnwrap d-flex justify-content-center">
                        <Link to={`/contact-us/`} className="opsBtnlink">
                            <p>{herosection.btn}</p>
                        </Link>
                    </div>
                </div>
            </div>
            <Navigation data={navbar} locale={locale}/>
        </div>
      </>
    )
}

export default Heading
